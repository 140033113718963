import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import CallToActRegister from '../partials/CallToActRegister.jsx'
import BlogList from '../partials/BlogList.jsx'

import {getOSInfo , translate } from '../../helpers/global'

import imgTech1 from '../../images/tech/tech-job-1.png'
import imgTech2 from '../../images/tech/tech-job-2.png'
import imgTech3 from '../../images/tech/tech-job-3.png'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class TechJob extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {
    const {
      globalGetLanguages,
      user
    } = this.props
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        <div className="section-top-solution relative double-triangle triangle-left" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='solution-top-box-hanging aqua'>
                  <div className="for-tag">Job Simulations</div>
                  <h1 className='m-t-0 m-b-16'>
                  Select the Right Candidate to<br/>Boost Retention
                  </h1>
                  <p className='m-b-0'>Our science-backed assessments help your team see if candidates are a good match, while realistic job previews allow candidates to decide if the role is right for them. With Job Simulations, you can discover untapped talent and identify your next top performer.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right flex-container flex-end align-items-center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgTech1} />
              </div>
            </div>
          </section>
          <div className='solution-back-top aqua desktop-hide' style={globalGetLanguages == 'en' ? {height: '633px'} : {height: '633px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech2} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='red-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                  Explore Candidate’s Potential with Science
                  </h1>
                  <p className='m-b-0'>
                  With ASTRNT's Job Simulation, candidates dive into relevant exercises that help them understand the role better. This science-based assessment not only informs them if it’s the right fit but also gives hiring teams key insights into their capabilities.
                  </p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='red-back-feature-right for-2 desktop-hide' style={globalGetLanguages == 'en' ? {height: '482.6px'} : {height: '482.6px'}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging'>
                  <h1 className='m-t-0 m-b-16 tech'>
                  Minimize Turnover and Boost ROI
                  </h1>
                  <p className='m-b-0'>Job Simulations help candidates grasp the job's opportunities and challenges, enabling them to assess their fit. Work history questions provide hiring teams with insights into candidates who are most likely to stay.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 32px'}} className='img-100' src={imgTech3} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-1 desktop-hide' style={globalGetLanguages == 'en' ? {height: '455.8px'} : {height: '455.8px'}}></div>
        </div>
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TechJob)
