// @flow

import React, { Fragment } from 'react'
import $ from 'jquery'
// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import initializers from '../../dependencies/initializers'

import { translate } from '../../helpers/global'
import AstrntLogo from '../../styles/img/astro-logo.svg?v=1'

import graduateIMG from '../../images/for-graduate.png'
import univIMG from '../../images/for-univ.png'
import govIMG from '../../images/for-gov.png'
import proIMG from '../../images/for-pro.png'
import blueIMG from '../../images/for-blue.png'
import assessIMG from '../../images/for-assess.png'
import talentIMG from '../../images/for-talent.png'

import solution1 from '../../images/solution-1.png'
import solution2 from '../../images/solution-2.png'
import solution3 from '../../images/solution-3.png'
import solution4 from '../../images/solution-4.png'

import flagUK from '../../images/flag-uk.svg'
import flagID from '../../images/flag-id.svg'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

type Props = {
	isNavbarFixed: boolean
};

class HeaderNewClear extends React.Component<Props> {
	constructor(props) {
		super(props)
		this.state = {
		menu: false
		};
		this.langContainRef = React.createRef();
		this.forContainRef = React.createRef();
		this.accContainRef = React.createRef();
		this.techContainRef = React.createRef();

		bindAll(this, [
			'handleRegisterLink',
			'toggleMenu',
			'detectClick'
			
		])
	}
	static defaultProps = {
		isOnStatusLogo: '',
		isOnStatusWork: '',
		isOnStatusFor: '',
	}

	toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }	

	handleLoginLink(e: Object): void {
		e.preventDefault()
			window.location = initializers.recruiter_host+'/auth/login'
	}

	handleRegisterLink(e: Object): void {
		e.preventDefault()
		const{
			history
		}=this.props
		
		history.push('/register')
		
	}

	showDropdownAccount() {
		if ($('#account-drop').hasClass('hide')) {
			$('#account-drop').removeClass('hide')
			$('#home-drop').addClass('hide')
			$('.drop-btn-account-mobile').addClass('is-open')
		} else {
			$('#account-drop').addClass('hide')
			$('.drop-btn-account-mobile').removeClass('is-open')
		}
	}

	showDropdown() {
		if ($('#home-drop').hasClass('hide')) {
			$('#home-drop').removeClass('hide')
			$('.drop-btn-lang').addClass('is-open')
			$('#account-drop').addClass('hide')
		} else {
			$('#home-drop').addClass('hide')
			$('.drop-btn-lang').removeClass('is-open')
		}
	}

	showDropdownFor() {
		if ($('#for-drop').hasClass('hide')) {
			$('#for-drop').removeClass('hide')
			// $('#id-angle-down-for').removeClass('fa-angle-down')
			// $('#id-angle-down-for').addClass('fa-angle-up')
			$('#id-a-for').addClass('is-open')
		} else {
			$('#for-drop').addClass('hide')
			// $('#id-angle-down-for').addClass('fa-angle-down')
			// $('#id-angle-down-for').removeClass('fa-angle-up')
			$('#id-a-for').removeClass('is-open')
		}
	}

	showDropdownTech() {
		if ($('#tech-drop').hasClass('hide')) {
			$('#tech-drop').removeClass('hide')
			$('.drop-btn-tech').addClass('is-open')
			$('#account-drop').addClass('hide')
			$('#for-drop').addClass('hide')
			$('#id-a-for').removeClass('is-open')
			$('header ul.for-nav').slideUp('fast');
			$('.overlay-header').fadeOut('fast');
		} else {
			$('#tech-drop').addClass('hide')
			$('.drop-btn-tech').removeClass('is-open')
		}
	}

	chooseOpt(val) {
	const url_api = initializers.api_host
	  if(val == 'logout') {
		Cookies.remove('astrnt_user_company');
		Cookies.remove('astrnt_user_email');
		Cookies.remove('astrnt_user_name');
		Cookies.remove('astrnt_user_phone');
		this.props.usersGetDetailAsync({})
		window.location = (url_api + '/auth/logout')
	  } else if(val === 'dashboard') {
	  	window.location = (url_api)
	  } else if(val === '1') {
	  	if (process.env.NODE_ENV === 'development') {
			window.location = 'http://localhost:3000/'
		} else if (url_api == 'https://beta.astrnt.co') {

			window.location = 'https://test.astrnt.co/'

		} else {
			window.location = 'https://astrnt.co/'
		}
	  } else {
	  	if (process.env.NODE_ENV === 'development') {
			window.location = 'http://localhost:3000/'
		} else if (url_api == 'https://beta.astrnt.co') {

			window.location = 'https://test.astrnt.id/'

		} else {
			window.location = 'https://astrnt.id/'
		}
	  }
	}

	componentDidMount() {
		const {globalGetLanguages} = this.props
		var flagHead = false;
	    var scrollTop = $(window).scrollTop();

		if(Cookies.get('astrnt_user_company')) {
			this.props.usersGetDetailAsync({
				company: Cookies.get('astrnt_user_company'),
				email: Cookies.get('astrnt_user_email'),
				name: Cookies.get('astrnt_user_name'),
				phone: Cookies.get('astrnt_user_phone')
			})
		}

	    // $(window).scroll(function(){
	    //     scrollTop = $(this).scrollTop();
	        
	    //     if(scrollTop >= 100){
	    //         if(flagHead == false){
	    //             flagHead = true;
	    //             $('header').addClass('on-scroll');
	    //             $('.logo img').fadeOut('fast', function(){
	    //                 $(this).attr('src', AstrntLogo).fadeIn('fast');
	    //             });
	    //         }
	    //     }else{
	    //         if(flagHead == true){
	    //             flagHead = false;
	    //             $('header').removeClass('on-scroll');
	    //             $('.logo img').fadeOut('fast', function(){
	    //                 $(this).attr('src', AstrntLogo).fadeIn('fast');
	    //             });
	    //         }
	    //     }
	        
	    //     $('.ban-img').css({'transform': 'translateY(' + scrollTop / 3 + 'px)'});
	    // });

	    if (globalGetLanguages == 'id') {
	    	$('.btn-lang-en').addClass('hide')
	    	$('.btn-lang-id').removeClass('hide')
	    } else {
	    	$('.btn-lang-en').removeClass('hide')
	    	$('.btn-lang-id').addClass('hide')
	    }
	    
	    $('header a.nav-mob').click(function(event){
	        event.preventDefault();
	        
	        if($('header ul.main-nav').is(':hidden')){
	            $(this).find('i').fadeOut('fast', function(){
	                $(this).attr('class', 'fas fa-times').fadeIn('fast');
	            });
	            $('header ul.main-nav').slideDown('fast');
	        }else{
	            $(this).find('i').fadeOut('fast', function(){
	                $(this).attr('class', 'fas fa-bars').fadeIn('fast');
	            });
	            $('header ul.main-nav').slideUp('fast');
	            closeFor();
	        }
	    });
	    
	    function closeFor(){
	        $('header ul.main-nav li a.is-open').removeClass('is-open');
	        $('header a.on-before').addClass('is-open');
	        $('header ul.for-nav').slideUp('fast');
	        $('.overlay-header').fadeOut('fast');
	    }
	    
	    $('.dropdown-desktop-button').click(function(event){
	        event.preventDefault();
	        
	        if(!$(this).hasClass('is-open')){
	            $('header a.is-open').addClass('on-before').removeClass('is-open');
	            $(this).addClass('is-open');
	            $('header ul.for-nav').slideDown('fast');
	            $('.overlay-header').fadeIn('fast');
	        }else{
	            closeFor();
	        }
	    });
	    
	    $('.overlay-header').click(function(){
	        closeFor();
	    });

	    $('.dropdown-for > div > a.a-for').click(function(event){
        event.preventDefault();
        
        if(!$(this).hasClass('is-on')){
            $(this).addClass('is-on').parent().find('div').slideDown('fast');
        }else{
            $(this).removeClass('is-on').parent().find('div').slideUp('fast');
        }
    	});

		window.addEventListener("click", this.detectClick)

	}

detectClick(event){
	if (this.langContainRef.current != null) {
		if (!this.langContainRef.current.contains(event.target)) {
			$('#home-drop').addClass('hide')
			$('.drop-btn-lang').removeClass('is-open')
		  }
	}
	if (this.forContainRef.current != null) {
		if (!this.forContainRef.current.contains(event.target)) {
			$('#for-drop').addClass('hide')
			$('#id-a-for').removeClass('is-open')
		  }
	}
	if (this.accContainRef.current != null) {
		if (!this.accContainRef.current.contains(event.target)) {
			$('#account-drop').addClass('hide')
			$('.drop-btn-account-mobile').removeClass('is-open')
		  }
	}
	if (this.techContainRef.current != null) {
		if (!this.techContainRef.current.contains(event.target)) {
			$('#tech-drop').addClass('hide')
			$('.drop-btn-tech').removeClass('is-open')
		  }
	}
	
}

  render() {
	const show = (this.state.menu) ? "show" : "" ;
	const {
		isOnStatusLogo,
		isOnStatusWork,
		isOnStatusFor,
		globalGetLanguages,
		user
	} = this.props

	const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
	
    return (
			<Fragment>
				<header className="clear">
					<div className="top-header">
					  <section>
						<Link to="/candidate-support">{renderHTML(translate(globalGetLanguages,'HEADER_CANDIDATES_INTERVIEWING'))}</Link>
					  </section>
					</div>
		          <section>
		            <Link to="/" className={`logo`}><img src={AstrntLogo} /></Link>
		            <a href="#" className="nav-mob"><i className="fas fa-bars" /></a>
		            <ul className="main-nav">
		            <li>
		            	<a href="#" className="dropdown-desktop-button desktop-hide-header">{translate(globalGetLanguages,'SOLUTIONS')} <i className="fas fa-angle-down" /></a>
		              <li id="id-li-drop-for" className="mobile-hide-header" ref={this.forContainRef}>
		              	<a id="id-a-for" onClick={() => this.showDropdownFor()}>{translate(globalGetLanguages,'SOLUTIONS')} <i id="id-angle-down-for" className="fas fa-angle-down" /></a>
		              	<div id="for-drop" className="dropdown-for hide">
		                	  <div>
		                	  	<a href="#" className="a-for">
								  <span>{translate(globalGetLanguages,'TALENT_ACQUISITION')}</span>
		                	  		<i className="fas fa-angle-down" />
		                	  	</a>
		                	  	<div style={{display: 'none'}}>
								  	<p>{translate(globalGetLanguages,'TALENT_ACQUISITION_DESC')}</p>
		                	  		<Link to={`/solution/talent-acquisition`}>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-arrow-right"></i></Link>
		                	  	</div>
		                	  </div>
		                	  <hr/>

		                	  <div>
		                	  	<a href="#" className="a-for">
								  <span>{translate(globalGetLanguages,'TALENT_MANAGEMENT')}</span>
		                	  		<i className="fas fa-angle-down" />
		                	  	</a>
		                	  	<div style={{display: 'none'}}>
								  	<p>{translate(globalGetLanguages,'TALENT_MANAGEMENT_DESC')}</p>
		                	  		<Link to={`/solution/talent-management`}>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-arrow-right"></i></Link>
		                	  	</div>
		                	  </div>
		                	  <hr/>

		                	  <div>
		                	  	<a href="#" className="a-for">
								  	<span>{translate(globalGetLanguages,'UNIVERSITY_ADMISSION')}</span>
		                	  		<i className="fas fa-angle-down" />
		                	  	</a>
		                	  	<div style={{display: 'none'}}>
								  	<p>{translate(globalGetLanguages,'UNIVERSITY_ADMISSION_DESC')}</p>
		                	  		<Link to={`/solution/university-admission`}>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-arrow-right"></i></Link>
		                	  	</div>
		                	  </div>
		                	  <hr/>

		                	  <div>
		                	  	<a href="#" className="a-for">
								  	<span>{translate(globalGetLanguages,'POWERED_BY_ASTRNT')}</span>
		                	  		<i className="fas fa-angle-down" />
		                	  	</a>
		                	  	<div style={{display: 'none'}}>
								  	<p>{translate(globalGetLanguages,'POWERED_BY_ASTRNT_DESC')}</p>
		                	  		<Link to={`/solution/powered-by-astrnt`}>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-arrow-right"></i></Link>
		                	  	</div>
		                	  </div>
		                	  <hr/>
                	  </div>
	          	          </li>
		              </li>
					  <li ref={this.techContainRef}>
	                	  <a className="drop-btn-tech" onClick={() => this.showDropdownTech()}>Our Tech<i id="id-angle-down" className="fas fa-angle-down" /></a>
	                	  <div id="tech-drop" className="dropdown-tech hide">
		                	  <div>
							  	<Link to={`/tech/video-interviewing`}>
					            	<div className="div-text-container">
					            		<h3>Video Interviewing</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
		                	  <div className="desktop-hide-header">
							  	<Link to={`/tech/structured-interview-builder`}>
					            	<div className="div-text-container">
					            		<h3>Structured Interview Builder</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
							  <div>
							  	<Link to={`/tech/assessments`}>
					            	<div className="div-text-container">
					            		<h3>Assessment</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
		                	  <div className="desktop-hide-header">
							  	<Link to={`/tech/job-simulations`}>
					            	<div className="div-text-container">
					            		<h3>Job Simulations</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
							  <div>
							  	<Link to={`/tech/holistic-assessments`}>
					            	<div className="div-text-container">
					            		<h3>Holistic Assessment</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
		                	  <div className="desktop-hide-header">
							  	<Link to={`/tech/nextgen-assessment-centers`}>
					            	<div className="div-text-container">
					            		<h3>NextGen Assessment Centers</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
							  <div className="mobile-hide-header">
							  	<Link to={`/tech/structured-interview-builder`}>
					            	<div className="div-text-container">
					            		<h3>Structured Interview Builder</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
							  <div className="mobile-hide-header">
							  	<Link to={`/tech/job-simulations`}>
					            	<div className="div-text-container">
					            		<h3>Job Simulations</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
							  <div className="mobile-hide-header">
							  	<Link to={`/tech/nextgen-assessment-centers`}>
					            	<div className="div-text-container">
					            		<h3>NextGen Assessment Centers</h3>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i><i className="fas fa-arrow-right"></i></a>
										<hr />
					            	</div>
				            	</Link>
							  </div>
	                	  </div>
		              </li>
					  <li><Link to={`/plans`}>{translate(globalGetLanguages,'PLANS')}</Link></li>
					  <li><Link to={`/#assess`}>{translate(globalGetLanguages,'HOMEPAGE_ASSESSMENTS')}</Link></li>
		              {/* <li><a href={initializers.blog_host} target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'LIFE')}</a></li> */}
		              <li ref={this.langContainRef}>
	                	  <a className="drop-btn-lang btn-lang-en" onClick={() => this.showDropdown()}><img src={flagUK} />English<i id="id-angle-down" className="fas fa-angle-down" /></a>
	                	  <a className="drop-btn-lang btn-lang-id" onClick={() => this.showDropdown()}><img src={flagID} />Indonesia<i id="id-angle-down" className="fas fa-angle-down" /></a>
	                	  <div id="home-drop" className="dropdown-lang hide">
		                	  <div onClick={() => this.chooseOpt('1')}><img src={flagUK} />English</div>
		                	  <div onClick={() => this.chooseOpt('2')}><img src={flagID} />Indonesia</div>
	                	  </div>
		              </li>
		              <li><a className="cursor-pointer" onClick={this.handleLoginLink}>{translate(globalGetLanguages,'LOGIN')}</a></li>
		              <li><Link to={{pathname: `/register`, query: { plan: 'es2t' }}}>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_GET_ASTRNT_FOR_FREE')}</Link></li>
					  <li ref={this.accContainRef} className="hide">
						<a className="drop-btn-account desktop-flex-hide" onClick={() => this.showDropdownAccount()}>{user?.get('name')?.charAt(0) || "A"}</a>
						<a className="drop-btn-account-mobile mobile-hide-header" onClick={() => this.showDropdownAccount()}>My Account<i id="" className="fas fa-angle-down" style={{float: 'right',marginTop: '20px'}} /></a>
						<div id="account-drop" className="dropdown-account hide">
								<div id="id-account-info" className='flex-container'>
									<div className='account-icon'>{user?.get('name') ? user.get('name').charAt(0) : "A"}</div>
									<div className='account-info'>
										<label>{user.get('name')}</label>
										<span>{user.get('email')}</span>
									</div>
								</div>
								<hr className='desktop-hide-header'/>
								<div onClick={() => this.chooseOpt('dashboard')}>Go to Astronaut Dashboard</div>
								<hr className='desktop-hide-header'/>
								<div onClick={() => this.chooseOpt('logout')}>Log Out</div>
	                	  </div>
						</li>
		            </ul>
		          </section>
		          <div className="desktop-hide-header">
			          <ul className="for-nav" style={{display: 'none'}}>
			          	<section>
				            <li>
				            	<Link to={`/solution/talent-acquisition`}>
					            	<img src={solution1} />
					            	<div>
					            		<span>{translate(globalGetLanguages,'TALENT_ACQUISITION')}</span>
					            		<p>{translate(globalGetLanguages,'TALENT_ACQUISITION_DESC')}</p>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i></a>
					            	</div>
				            	</Link>
							</li>
							<li>
				            	<Link to={`/solution/talent-management`}>
					            	<img src={solution2} />
					            	<div>
					            		<span>{translate(globalGetLanguages,'TALENT_MANAGEMENT')}</span>
					            		<p>{translate(globalGetLanguages,'TALENT_MANAGEMENT_DESC')}</p>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i></a>
					            	</div>
				            	</Link>
							</li>
							<li>
				            	<Link to={`/solution/university-admission`}>
					            	<img src={solution3} />
					            	<div>
					            		<span>{translate(globalGetLanguages,'UNIVERSITY_ADMISSION')}</span>
					            		<p>{translate(globalGetLanguages,'UNIVERSITY_ADMISSION_DESC')}</p>
					            		{/* <p>{renderHTML(translate(globalGetLanguages,'HEADER_GOVERNMENTS_TEXT'))}</p> */}
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i></a>
					            	</div>
				            	</Link>
							</li>
							<li>
				            	<Link to={`/solution/powered-by-astrnt`}>
					            	<img src={solution4} />
					            	<div>
					            		<span>{translate(globalGetLanguages,'POWERED_BY_ASTRNT')}</span>
					            		<p>{translate(globalGetLanguages,'POWERED_BY_ASTRNT_DESC')}</p>
					            		<a>{translate(globalGetLanguages,'HEADER_LEARN_MORE')} <i className="fas fa-chevron-right"></i></a>
					            	</div>
				            	</Link>
							</li>
				        </section>
			          </ul>
		          </div>
		        </header>
		        <div className="overlay-header force-hide-mobile" style={{display: 'none'}} />
		    </Fragment>
		)
  }
}

HeaderNewClear.defaultProps = {
	isNavbarFixed: true
}

const mapStateToProps = (state, ownProps) => {
	return {
		globalGetLanguages:state.globalGetLanguages,
		user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
	}
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(HeaderNewClear))
